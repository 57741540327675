import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  rate: 0.0,
  initializing: true,
  selectedWarehouse: localStorage.getItem('selected_warehouse') ?? {},
};

// ==============================|| SLICE - MENU ||============================== //
const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setRate(state, action) {
      state.rate = action.payload;
    },
    setInitializing(state, action) {
      state.initializing = action.payload;
    },
    setSelectWarehouse(state, action) {
      // console.log(action.payload)
      // localStorage.setItem('selected_warehouse', action.payload ?? {})
      state.selectedWarehouse = action.payload;
    }

  }
});

export default app.reducer;

export const { setRate, setInitializing, setSelectWarehouse } = app.actions;
